/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Box, FormControlLabel, Radio, RadioGroup, Stack} from '@mui/material'
import React, {useState, forwardRef, useContext, useEffect} from 'react'
import MultiStateTextInput from '../MultiStateTextInput'
import {AppContext} from '../../App'
import Panel from '../../components/Panel/Panel.js';
import {logEvent} from '../../utils'

export const PanelAppManagement = forwardRef(({}, ref) => {
	const [panelState, setPanelState] = useState(null)
	const {useAppState, errors, setApp, setErrors, authToken} = useContext(AppContext)
	const [manage, setManage] = useAppState('manage', true)
	const [appleAccountName, setAppleAccountName] = useAppState('appleAccountName', true)
	const [dunsNumber, setDunsNumber] = useAppState('dunsNumber', true)

	useEffect(()=>{
		setErrors([])
		setApp((state)=>({
			...state,
			manage,
			appleAccountName,
			dunsNumber,
		}))
	}, [manage, appleAccountName, dunsNumber])

	useEffect(()=>{
		if (manage) {
			setPanelState(null)
			return
		}

		const appleAccountNameErrors = checkErrors('appleAccountName')
		const dunsNumberErrors = checkErrors('dunsNumber')
		if ((appleAccountNameErrors && appleAccountNameErrors.length > 0) || (dunsNumberErrors && dunsNumberErrors.length > 0)) {
			setPanelState("warning")
		}

		if (appleAccountName !== '' && dunsNumber !== '') {
			setPanelState("success")
		} else {
			setPanelState(null)
		}

	}, [errors])

	const checkErrors = (key) => {
		if (!errors) return null
		const keyErrors = errors.filter(err=>(err.key===key))
		if (!keyErrors || keyErrors.length === 0) return null
		return keyErrors.map(err=>err.error)
	}

	return (
		<Panel
			title="Apple App Management"
			introText="Please provide your Apple Developer Team ID and DUNS number so we can build your app."
			panelState={panelState}
		>
			{!manage && <Box>
				<Box mt={4} fullWidth>
					<strong>Apple Developer Team ID</strong>
					<MultiStateTextInput
						name={'appleAccountName'}
						value={appleAccountName}
						onChange={evt=>setAppleAccountName(evt.target.value)}
						onClear={evt=>setAppleAccountName('')}
						onBlur={evt=>{ logEvent(authToken, 'ui-field-touched', {field: 'appleAccountName', appleAccountName: evt.target.value}) }}
						errors={checkErrors('appleAccountName')}
					/>
				</Box>
				<Box mt={4} fullWidth>
					<strong>DUNS Number</strong>
					<MultiStateTextInput
						name={'dunsNumber'}
						value={dunsNumber}
						onChange={evt=>setDunsNumber(evt.target.value)}
						onClear={evt=>setDunsNumber('')}
						onBlur={evt=>{ logEvent(authToken, 'ui-field-touched', {field: 'dunsNumber', dunsNumber: evt.target.value}) }}
						errors={checkErrors('dunsNumber')}
					/>
				</Box>
			</Box>}
		</Panel>
	)
})

export default PanelAppManagement
